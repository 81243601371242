<template>
  <v-main>
    <v-navigation-drawer
      v-model="drawer"
      app>
      <v-card
        color="primary"
        :style="{ color: 'white' }"
        flat
        tile>
        <v-card-title>
          <avatar
            class="mr-4"
            color="white"
            :username="name">
          </avatar>
          <span>
            {{ name }}
          </span>
        </v-card-title>
        <v-card-actions>
          <v-btn
            @click="$router.push('/mypage')"
            color="secondary"
            depressed
            block>
            마이페이지
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-list>
        <template
          v-for="(item, idx) in menu.items">
          <v-list-item
            v-if="(typeof item.show === 'function')
              ? (userInfo && item.show(userInfo.permission, userInfo.meta)) : item.show"
            :key="`menu_${idx}`"
            :to="item.to">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.text }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      color="primary"
      app>
      <v-app-bar-nav-icon
        :style="{ color: 'white' }"
        @click="drawer=!drawer">
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-container
      class="align-start"
      fluid
      fill-height>
      <router-view>
      </router-view>
    </v-container>
  </v-main>
</template>

<script>
import DialogSupport, { makeShowFlag } from '@/components/mixins/dialog_support';
import Avatar from 'vue-avatar';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  mixins: [
    DialogSupport,
  ],
  components: {
    Avatar,
  },
  data: () => ({
    menu: {
      show: false,
      items: [
        {
          to: '/',
          text: '공급자 상태',
          icon: 'mdi-send-check',
          show: (permission) => (permission.processor),
        },
        {
          to: '/providers',
          text: '공급자 관리',
          icon: 'mdi-send-check',
          show: (permission) => (permission.processor),
        },
        {
          to: '/providergroups',
          text: '공급자 그룹 관리',
          icon: 'mdi-send-check',
          show: (permission) => (permission.processor),
        },
        {
          to: '/categories',
          text: '연락처 분류 관리',
          icon: 'mdi-send-check',
          show: (permission) => (permission.processor),
        },
        {
          to: '/templates',
          text: '메시지 양식 관리',
          icon: 'mdi-send-check',
          show: (permission) => (permission.processor),
        },
        {
          to: '/send',
          text: '메시지 전송',
          icon: 'mdi-send-check',
          show: (permission) => (permission.sender),
        },
        {
          to: '/history',
          text: '전송 기록',
          icon: 'mdi-history',
          show: (permission) => (permission.sender),
        },
      ],
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    drawer: makeShowFlag('drawer'),
    name() {
      return (this.userInfo != null) ? this.userInfo.name : '';
    },
  },
};
</script>
