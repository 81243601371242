import Vue from 'vue';
import * as format from 'string-format';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/comm';
import '@/plugins/storage';
import '@/plugins/firebase';
import '@/plugins/fcm';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

format.extend(String.prototype);

(() => {
  const token = Vue.ls.get('access');
  if (token === null) {
    store.commit('auth/initialized', true);
  }
})();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
