export default {
  apiHost: (() => ((process.env.NODE_ENV === 'production')
    ? 'https://msg.manolab.co.kr'
    : 'http://localhost:5000'))(),
  thirdParty: (() => ((process.env.NODE_ENV === 'production')
    ? 'https://manolab.co.kr'
    : 'https://manolab.co.kr'))(),
  thirdPartyName: (() => ((process.env.NODE_ENV === 'production')
    ? 'msg_bibc_co_kr'
    : 'test_msg_bibc_co_kr'))(),
};
