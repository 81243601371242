import '../firebase';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const vapidKey = 'BIdnAbPw_gRbh8b7uCeg8AGJPoveyZgvL2QTVnYHWkRphSXPkr62nEZaG_xO-egSOcmXGX01l9P-VRMoNIHqrVk';

// Initialize Firebase
const messaging = getMessaging();

onMessage(messaging, (payload) => {
  console.log('message received:', payload);
});

export default {
  requestPermissions: async () => (Notification.requestPermission()),
  permission: () => (Notification.permission),
  register: () => {},
  createChannel: () => {},
  deleteChannel: () => {},
  onPushNotificationReceived: () => {},
  onPushNotificationActionPerformed: () => {},
  onRegistration: () => {},
  onRegistrationError: () => {},
  async getFCMToken() {
    return getToken(messaging, { vapidKey });
  },
  setAutoInit: () => {},
};
