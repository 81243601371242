import { initializeApp } from 'firebase/app';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAtO-jnzR6uimGJe3yIpfpCK6ipqn-wNsA',
  authDomain: 'barun-sms.firebaseapp.com',
  projectId: 'barun-sms',
  storageBucket: 'barun-sms.appspot.com',
  messagingSenderId: '228057752897',
  appId: '1:228057752897:web:11620902f44017f26fadc0',
  measurementId: 'G-KRW13X8MMK',
};

// Initialize Firebase
export default initializeApp(firebaseConfig);
