<template>
  <v-app id="app">
    <v-system-bar
      v-if="!connected"
      app
      color="warning">
      <v-icon>mdi-lan-disconnect</v-icon>
      <span>서버와 연결이 끊어졌습니다.</span>
    </v-system-bar>
    <router-view/>
    <v-snackbar
      v-model="alertShowFlag"
      :color="alertInfo.color"
      :timeout="alertInfo.timeout"
      top>
      {{ alertInfo.message }}
    </v-snackbar>
    <v-snackbar
      v-model="showPermissionFlag"
      color="info"
      :timeout="-1"
      vertical>
      푸시 알림 수신을 위해 권한이 필요합니다.
      <template v-slot:action="{ attrs }">
        <v-btn
          @click="requestPermission()"
          text
          v-bind="attrs">
          권한 요청
        </v-btn>
        <v-btn
          @click="showPermissionFlag = false"
          text
          v-bind="attrs">
          닫기
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import alertSupport from '@/components/mixins/alert_support';
import fcm from '@/plugins/fcm';

export default {
  name: 'App',
  mixins: [
    alertSupport,
  ],
  data: () => ({
    fcm,
  }),
  computed: {
    ...mapGetters({
      connected: 'auth/connected',
      initialized: 'auth/initialized',
      userInfo: 'auth/userInfo',
      verified: 'auth/verified',
      alertShow: 'alert/alertShow',
      alertInfo: 'alert/alertInfo',
      showPermission: 'auth/showPermission',
    }),
    alertShowFlag: {
      get() {
        return this.alertShow;
      },
      set(value) {
        this.updateShow(value);
      },
    },
    showPermissionFlag: {
      get() {
        return this.showPermission;
      },
      set(value) {
        this.updateShowPermission(value);
      },
    },
  },
  watch: {
    connected: {
      immediate: false,
      handler() {
        if (this.connected && this.initialized) {
          this.$router.checkAuth();
        }
      },
    },
    initialized: {
      immediate: false,
      handler() {
        if (this.connected && this.initialized) {
          this.$router.checkAuth();
        }
      },
    },
    verified: {
      immediate: true,
      async handler(value) {
        if (this.connected && this.initialized) {
          this.$router.checkAuth();
        }

        if (value) {
          // TODO: 로그인 후 처리해야 하는 작업 정의
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      updateShow: 'alert/updateShow',
      updateShowPermission: 'auth/showPermission',
    }),
    async requestPermission() {
      this.showPermissionFlag = false;

      const result = await this.fcm.requestPermissions();
      if (result === 'granted') {
        await this.$store.dispatch('auth/registerFCMToken');
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
