import Vue from 'vue';
import Vuex from 'vuex';

// eslint-disable-next-line import/no-cycle
import auth from './auth';
import router from './router';
import alert from './alert';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    router,
    alert,
  },
});
